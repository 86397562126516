import React, { Component } from "react";
import "./navbar.css";
import "./css/style.css";
import ReactDOM from "react-dom";
// import disableScroll from 'disable-scroll';
import { HashRouter } from "react-router-dom";
import { Link as Link2 } from "react-router-dom";
import { Link, Events, animateScroll as scroll, scroller } from "react-scroll";
import { slide as Menu2 } from "react-burger-menu";
import insta from "./images/seerslab/insta.png";
import facebook from "./images/seerslab/facebook.png";
import seersLogo from "./images/seerslab/logo_white.png";
import twitter from "./images/seerslab/twitter.png";
import linked from "./images/seerslab/linked.png";

/* App.jsx */
class NAV extends Component {
  scrollToTop() {
    scroll.scrollToTop();
  }
  scrollTo() {
    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }
  scrollToWithContainer() {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register("end", () => {
        resolve();
        Events.scrollEvent.remove("end");
      });

      scroller.scrollTo("scroll-container", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    });

    goToContainer.then(() =>
      scroller.scrollTo("scroll-container-second-element", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        containerId: "scroll-container",
      })
    );
  }
  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }
  componentWillMount() {
    switch (window.location.href.split("/")[4]) {
      case "services":
        this.setState({ isService: true });
        this.setState({ isCompany: false });
        this.setState({ isPress: false });
        this.setState({ isRelation: false });
        break;
      case "company":
        this.setState({ isService: false });
        this.setState({ isChanged: true });
        this.setState({ isPress: false });
        this.setState({ isRelation: false });

        switch (parseInt(window.location.href.split("/")[5])) {
          case 1:
            this.setState({ isSolution: true });
            this.setState({ isContents: false });
            this.setState({ isPlatform: false });
            break;
          case 2:
            this.setState({ isSolution: false });
            this.setState({ isContents: true });
            this.setState({ isPlatform: false });
            break;
          case 3:
            this.setState({ isSolution: false });
            this.setState({ isContents: false });
            this.setState({ isPlatform: true });
            break;
        }

        break;
      case "press":
        this.setState({ isService: false });
        this.setState({ isCompany: false });
        this.setState({ isPress: true });
        this.setState({ isRelation: false });

        break;
      case "relations":
        this.setState({ isService: false });
        this.setState({ isCompany: false });
        this.setState({ isPress: false });
        this.setState({ isRelation: true });

        break;
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      isChanged: false,
      isService: false,
      isCompany: false,
      isPress: false,
      isRelation: false,
      isSolution: false,
      isContents: false,
      isPlatform: false,
    };
    this.scrollToTop = this.scrollToTop.bind(this);
  }
  handleService() {
    this.setState({ isService: true });
    this.setState({ isCompany: false });
    this.setState({ isPress: false });
    this.setState({ isRelation: false });
  }

  handleMenuClick() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }
  handleChangeClick() {
    this.setState({ isChanged: !this.state.isChanged });
  }
  handleLinkClick(val) {
    this.setState({ menuOpen: false });

    if (val === "홈") {
      window.location.href = "#/";
      window.scrollTo(0, 0);
    } else if (
      val ===
      `${localStorage.getItem("lang") == "eng" ? "Company" : "회사소개"}`
    ) {
      window.location.href = "#/intro";
      window.scrollTo(0, 0);
    } else if (
      val ===
      `${localStorage.getItem("lang") == "eng" ? "Business" : "사업분야"}`
    ) {
      window.location.href = "#/company";
      window.scrollTo(0, 0);
    } else if (
      val === `${localStorage.getItem("lang") == "eng" ? "Career" : "채용정보"}`
    ) {
      window.location.href = "#/career";
      window.scrollTo(0, 0);
    } else if (val === "Contact Us") {
      window.location.href = "#/contact";
      window.scrollTo(0, 0);
    }
  }

  render() {
    const styles = {
      container: {
        position: "absolute",
        top: 10,
        bottom: 0,
        left: 0,
        zIndex: "9999",
        opacity: 1,
        display: "flex",
        alignItems: "center",
        background: "white",
        width: "100%",
        color: "white",
        fontFamily: "Lobster",
      },
      logo: {
        margin: "0 auto",
        padding: "0px 47px 0 0",
        color: "black",
        marginTop: "5px",
      },
    };
    const menu = [
      "홈",
      `${localStorage.getItem("lang") == "eng" ? "Company" : "회사소개"}`,
      `${localStorage.getItem("lang") == "eng" ? "Business" : "사업분야"}`,
      `${localStorage.getItem("lang") == "eng" ? "Career" : "채용정보"}`,
      "Contact Us",
    ];
    const menuItems = menu.map((val, index) => {
      return (
        <div>
          {/* <MenuItem
            key={index}
            delay={`${index * 0.1}s`}
            onClick={() => {
              this.handleLinkClick(val);
            }}
          >
            {val}
          </MenuItem> */}
        </div>
      );
    });

    return (
      <HashRouter>
        <div>
          <link href={require("./navbar.css")} rel="stylesheet" />
          <link href={require("./css/style.css")} rel="stylesheet" />

          <div style={styles.container}>
            ""
            <MenuButton
              open={this.state.menuOpen}
              onClick={() => this.handleMenuClick()}
              color="black"
            />
            <a href="/" style={styles.logo}>
              <img
                src={require("./images/logo_.png")}
                width={100}
                height="auto"
                alt="header-logo"
              />
              <br />
              <br />
            </a>
          </div>
          {/* <Menu open={this.state.menuOpen}>{menuItems}</Menu> */}
          <Menu className="menulist" open={this.state.menuOpen}>
            <br />
            <br />
            <div
              className="lineTop2"
              style={{
                display: `${
                  this.state.isService && !this.state.isChanged
                    ? "block"
                    : "none"
                }`,
              }}
            ></div>
            <div
              className="lineTop3"
              style={{
                display: `${
                  this.state.isPress && !this.state.isChanged ? "block" : "none"
                }`,
              }}
            ></div>
            <div
              className="lineTop4"
              style={{
                display: `${
                  this.state.isRelation && !this.state.isChanged
                    ? "block"
                    : "none"
                }`,
              }}
            ></div>
            {/* non-clicked 사업분야 */}
            <a
              className="menulist"
              style={{
                zIndex: 100,
                width: "100%",
                position: "absolute",
                right: 0,
                left: 0,
                margin: "auto",
                top: "15%",
                fontSize: "30px",
                display: `${this.state.isChanged ? "none" : ""}`,
                backgroundColor: "#fff",
                width: "40%",
              }}
              href="#/intro"
            >
              <a style={{ color: `${this.state.isService ? "#14c8ab" : ""}` }}>
                {localStorage.getItem("lang") == "eng" ? "Company" : "회사소개"}
              </a>
            </a>
            <br />
            <br />
            <a
              className="menulist"
              style={{
                zIndex: 101,
                width: "100%",
                position: "absolute",
                right: 0,
                left: 0,
                margin: "auto",
                top: "27.5%",
                fontSize: "30px",
                display: `${this.state.isChanged ? "none" : ""}`,
              }}
              onClick={() => {
                this.handleChangeClick();
              }}
            >
              {localStorage.getItem("lang") == "eng" ? "Business" : "사업분야"}
            </a>
            <br />
            <br />
            <a
              className="menulist"
              style={{
                zIndex: 100,
                width: "100%",
                position: "absolute",
                right: 0,
                left: 0,
                margin: "auto",
                top: "40%",
                fontSize: "30px",
                display: `${this.state.isChanged ? "none" : ""}`,
                backgroundColor: "#fff",
                width: "40%",
              }}
              href="#/career"
            >
              <a style={{ color: `${this.state.isPress ? "#14c8ab" : ""}` }}>
                {localStorage.getItem("lang") == "eng" ? "Career" : "채용정보"}
              </a>
            </a>
            <br />
            <br />
            <a
              className="menulist"
              style={{
                zIndex: 100,
                width: "100%",
                position: "absolute",
                right: 0,
                left: 0,
                margin: "auto",
                top: "52.5%",
                fontSize: "30px",
                display: `${this.state.isChanged ? "none" : ""}`,
                backgroundColor: "#fff",
                width: "60%",
              }}
              href="#/contact"
            >
              <a style={{ color: `${this.state.isRelation ? "#14c8ab" : ""}` }}>
                CONTACT US
              </a>
            </a>
            <br />
            <br />
            <a
              className="menulist"
              style={{
                zIndex: 100,
                width: "100%",
                position: "absolute",
                right: 0,
                left: 0,
                margin: "auto",
                top: "65%",
                fontSize: "30px",
                display: `${this.state.isChanged ? "none" : ""}`,
                backgroundColor: "#fff",
                width: "40%",
              }}
              href="#/notice"
            >
              <a style={{ color: `${this.state.isPress ? "#14c8ab" : ""}` }}>
                {localStorage.getItem("lang") == "eng" ? "Notice" : "공고"}
              </a>
            </a>
            <br />
            <div
              className="lineTop"
              style={{ display: `${this.state.isChanged ? "" : "none"}` }}
            ></div>
            {/* clicked 사업분야 */}
            <a
              className="menulist"
              style={{
                zIndex: 100,
                width: "100%",
                position: "absolute",
                right: 0,
                left: 0,
                margin: "auto",
                top: "10%",
                fontSize: "30px",
                display: `${this.state.isChanged ? "" : "none"}`,
              }}
              href="#/intro"
            >
              {localStorage.getItem("lang") == "eng" ? "Company" : "회사소개"}
            </a>
            <br />
            <br />
            <a
              id="busy"
              className="menulist"
              style={{
                zIndex: 101,
                width: "40%",
                position: "absolute",
                right: 0,
                left: 0,
                margin: "auto",
                top: "20%",
                fontSize: "30px",
                display: `${this.state.isChanged ? "" : "none"}`,
                backgroundColor: "#fff",
              }}
              onClick={() => {
                this.handleChangeClick();
              }}
            >
              <a style={{ color: "#0abebb" }}>
                {localStorage.getItem("lang") == "eng"
                  ? "Business"
                  : `${
                      localStorage.getItem("lang") == "eng"
                        ? "Business"
                        : "사업분야"
                    }`}
              </a>
            </a>
            <br />
            <br />
            <a
              className="menulist"
              style={{
                zIndex: 100,
                width: "100%",
                position: "absolute",
                right: 0,
                left: 0,
                margin: "auto",
                top: "27%",
                fontSize: "30px",
                display: `${this.state.isChanged ? "" : "none"}`,
                fontSize: "20px",
              }}
            >
              <a
                style={{ color: "#999999" }}
                href="#/company/1"
                className="mlist"
              >
                <a
                  style={{
                    color: `${this.state.isSolution ? "#000" : ""}`,
                    textDecoration: `${
                      this.state.isSolution ? "underline" : ""
                    }`,
                  }}
                >
                  {localStorage.getItem("lang") == "eng"
                    ? "Solution"
                    : "솔루션 사업"}
                </a>
              </a>
            </a>
            <br />
            <br />
            <a
              className="menulist"
              style={{
                zIndex: 100,
                width: "100%",
                position: "absolute",
                right: 0,
                left: 0,
                margin: "auto",
                top: "32%",
                fontSize: "30px",
                display: `${this.state.isChanged ? "" : "none"}`,
                fontSize: "20px",
              }}
            >
              <a
                style={{ color: "#999999" }}
                href="#/company/2"
                className="mlist"
              >
                <a
                  style={{
                    color: `${this.state.isContents ? "#000" : ""}`,
                    textDecoration: `${
                      this.state.isContents ? "underline" : ""
                    }`,
                  }}
                >
                  {localStorage.getItem("lang") == "eng"
                    ? "Contents"
                    : "콘텐츠 사업"}
                </a>
              </a>
            </a>
            <br />
            <br />
            <a
              className="menulist"
              style={{
                zIndex: 100,
                width: "100%",
                position: "absolute",
                right: 0,
                left: 0,
                margin: "auto",
                top: "37%",
                fontSize: "30px",
                display: `${this.state.isChanged ? "" : "none"}`,
                fontSize: "20px",
              }}
            >
              <a
                style={{ color: "#999999" }}
                href="#/company/3"
                className="mlist"
              >
                <a
                  style={{
                    color: `${this.state.isPlatform ? "#000" : ""}`,
                    textDecoration: `${
                      this.state.isPlatform ? "underline" : ""
                    }`,
                  }}
                >
                  {localStorage.getItem("lang") == "eng"
                    ? "Platform"
                    : "플랫폼 사업"}
                </a>
              </a>
            </a>
            <br />
            <br />
            <a
              className="menulist"
              style={{
                zIndex: 100,
                width: "100%",
                position: "absolute",
                right: 0,
                left: 0,
                margin: "auto",
                top: "47%",
                fontSize: "30px",
                display: `${this.state.isChanged ? "" : "none"}`,
              }}
              href="#/career"
            >
              {localStorage.getItem("lang") == "eng" ? "Career" : "채용정보"}
            </a>
            <br />
            <br />
            <a
              className="menulist"
              style={{
                zIndex: 100,
                width: "100%",
                position: "absolute",
                right: 0,
                left: 0,
                margin: "auto",
                top: "57%",
                fontSize: "30px",
                display: `${this.state.isChanged ? "" : "none"}`,
              }}
              href="#/contact"
            >
              CONTACT US
            </a>
            <br /> <br />
            <a
              className="menulist"
              style={{
                zIndex: 100,
                width: "100%",
                position: "absolute",
                right: 0,
                left: 0,
                margin: "auto",
                top: "67%",
                fontSize: "30px",
                display: `${this.state.isChanged ? "" : "none"}`,
              }}
              href="#/notice"
            >
              {localStorage.getItem("lang") == "eng" ? "Notice" : "공고"}
            </a>
            <br />
            <a
              href="https://kr.linkedin.com/organization-guest/company/seerslab?challengeId=AQFsBiLHNA0ABwAAAXbafwshnEvX4YF-ZAipC9fhffxxXfzj2aNlrYANNuUerB3JZbAL9vGEN-zkzxxJdymtgSVJByre_ZUQlg&submissionId=d5b295f7-7ccf-5716-d80e-3f857794bf13"
              target="_blank"
            >
              <img
                style={{
                  zIndex: 99,
                  width: "39.5px",
                  position: "absolute",
                  right: "60%",
                  left: 0,
                  margin: "auto",
                  bottom: "18%",
                }}
                src={linked}
              ></img>
            </a>
            <a href="www.naver.com" target="_blank">
              <img
                style={{
                  zIndex: 99,
                  width: "39.5px",
                  position: "absolute",
                  right: "20%",
                  left: 0,
                  margin: "auto",
                  bottom: "18%",
                }}
                src={twitter}
              ></img>
            </a>
            <a href="https://www.facebook.com/lollicam/" target="_blank">
              <img
                style={{
                  zIndex: 99,
                  width: "39.5px",
                  position: "absolute",
                  right: 0,
                  left: "20%",
                  margin: "auto",
                  bottom: "18%",
                }}
                src={facebook}
              ></img>
            </a>
            <a
              href="https://www.instagram.com/seerslab_official/"
              target="_blank"
            >
              <img
                style={{
                  zIndex: 99,
                  width: "39.5px",
                  position: "absolute",
                  right: 0,
                  left: "60%",
                  margin: "auto",
                  bottom: "18%",
                }}
                src={insta}
              ></img>
            </a>
          </Menu>
        </div>
      </HashRouter>
    );
  }
}

/* Menu.jsx */
class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open ? this.props.open : false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open });
    }
  }

  render() {
    const styles = {
      container: {
        position: "absolute",
        top: 0,
        left: 0,
        height: this.state.open ? "100vh" : 0,
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        background: "white",
        opacity: 1,
        color: "#fafafa",
        transition: "height 0.7s ease",
        zIndex: 2,
      },
      // menuList: {
      //   paddingTop: "3rem"
      // }
    };
    return (
      <div style={styles.container}>
        {this.state.open ? (
          <div style={styles.menuList}>{this.props.children}</div>
        ) : null}
      </div>
    );
  }
}

/* MenuButton.jsx */
class MenuButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open ? this.props.open : false,
      color: this.props.color ? this.props.color : "black",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open });
    }
  }

  handleClick() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const styles = {
      container: {
        height: "32px",
        width: "32px",
        display: "flex",
        top: 10,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "-8px",
        // cursor: 'pointer',
        padding: "4px",
      },
      line: {
        height: "2px",
        width: "20px",
        background: this.state.color,
        transition: "all 0.2s ease",
      },
      lineTop: {
        transform: this.state.open ? "rotate(45deg)" : "none",
        transformOrigin: "top left",
        marginBottom: "5px",
      },
      lineMiddle: {
        opacity: this.state.open ? 0 : 1,
        transform: this.state.open ? "translateX(-16px)" : "none",
      },
      lineBottom: {
        transform: this.state.open ? "translateX(-1px) rotate(-45deg)" : "none",
        transformOrigin: "top left",
        marginTop: "5px",
      },
    };
    return (
      <div
        style={styles.container}
        onClick={
          this.props.onClick
            ? this.props.onClick
            : () => {
                this.handleClick();
              }
        }
      >
        <div style={{ ...styles.line, ...styles.lineTop }} />
        <div style={{ ...styles.line, ...styles.lineMiddle }} />
        <div style={{ ...styles.line, ...styles.lineBottom }} />
      </div>
    );
  }
}

/* Main.jsx */
class Main extends Component {
  render() {
    const styles = {
      main: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      },
    };

    return (
      <div style={styles.main}>
        <NAV />
      </div>
    );
  }
}

ReactDOM.render(<Main />, document.querySelector("#root"));

export default NAV;

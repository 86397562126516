import React, { Component } from "react";
import { Link, Events, animateScroll as scroll, scroller } from "react-scroll";
import { Link as Link2 } from "react-router-dom";
// import NAV from "./navbar";
import NAV from "./navbarWithNotice";
import { connect, bindActionCreators } from "react-redux";
// import * as actions from "../actions";
import "./navbar.css";
import { isIOS, isAndroid } from "react-device-detect";

const propTypes = {
  Text1: "홈",
  Text2: `${localStorage.getItem("lang") == "eng" ? "Company" : "회사소개"}`,
  Text3: `${localStorage.getItem("lang") == "eng" ? "Business" : "사업분야"}`,
  Text4: `${localStorage.getItem("lang") == "eng" ? "Career" : "채용정보"}`,
  Text5: "Contact Us",
  Text6: `${localStorage.getItem("lang") == "eng" ? "Notice" : "공고"}`,
};

const defaultProps = {
  Text1: "홈",
  Text2: `${localStorage.getItem("lang") == "eng" ? "Company" : "회사소개"}`,
  Text3: `${localStorage.getItem("lang") == "eng" ? "Business" : "사업분야"}`,
  Text4: `${localStorage.getItem("lang") == "eng" ? "Career" : "채용정보"}`,
  Text5: "Contact Us",
  Text6: `${localStorage.getItem("lang") == "eng" ? "Notice" : "공고"}`,
};
class MainNav extends Component {
  constructor(props) {
    super(props);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  scrollToTop() {
    scroll.scrollToTop();
  }
  scrollTo() {
    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }
  scrollToWithContainer() {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register("end", () => {
        resolve();
        Events.scrollEvent.remove("end");
      });

      scroller.scrollTo("scroll-container", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    });

    goToContainer.then(() =>
      scroller.scrollTo("scroll-container-second-element", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        containerId: "scroll-container",
      })
    );
  }
  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }
  render() {
    return (
      <header id="header" className="header">
        <link href={require("./navbar.css")} rel="stylesheet" />
        <nav
          className="navbar fixed-top navbar-expand-lg navbar-dark bg-tra"
          style={{ zIndex: 98 }}
        >
          <div className="container">
            <Link
              activeClass="active"
              to="page"
              spy={true}
              smooth={true}
              duration={500}
            >
              <Link2 to="/" className="logo-white">
                <img
                  style={{ marginTop: "21px", marginBottom: "25px" }}
                  src={require("./images/logo_.png")}
                  width={150}
                  height="auto"
                  alt="header-logo"
                />
              </Link2>
            </Link>
            {/* <a href="/" className="navbar-brand logo-black">""</a> */}
            {/* Responsive Menu Button */}
            <button className="navbar-toggler">
              <NAV />
            </button>
            {/* Navigation Menu */}
            <div
              id="navbarSupportedContent"
              className="collapse navbar-collapse"
            >
              <ul
                className="navbar-nav"
                style={{ fontSize: "15px", left: 0, right: 0 }}
              >
                {/* <li>
                  <Link
                    activeClass="active"
                    to="page"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <Link2
                      className="nav-item nl-simple nav-link"
                      to="/"
                    >
                      <Link
                        activeClass="active"
                        to="page"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        <Link2 to="/">{this.props.Text1}</Link2>
                      </Link>
                    </Link2>
                  </Link>
                </li> */}
                <li style={{ marginRight: "50px", padding: "10px" }}>
                  <Link
                    activeClass="active"
                    to="page"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <Link2 className="nav-item nl-simple nav-link" to="/intro">
                      <Link
                        activeClass="active"
                        to="page"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        <Link2 class="currentRoute" to="/intro">
                          {this.props.Text2}
                        </Link2>
                      </Link>
                    </Link2>
                  </Link>
                </li>
                {/* <li style={{ marginRight: "100px" }}>

                </li>
                <li style={{ marginRight: "100px" }}>

                </li>
                <li style={{ marginRight: "100px" }}>

                </li> */}
                <li style={{ marginRight: "20px", padding: "10px" }}>
                  <Link
                    activeClass="active"
                    to="page"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <Link2
                      className="nav-item nl-simple nav-link"
                      to="/company"
                    >
                      <Link
                        activeClass="active"
                        to="page"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        <Link2
                          class="currentRoute"
                          to="/company"
                          onClick={() => {
                            if (localStorage.getItem("button")) {
                              let value = localStorage.getItem("button");
                              localStorage.setItem("button", ++value);
                            } else {
                              localStorage.setItem("button", 1);
                            }
                          }}
                        >
                          {this.props.Text3}
                        </Link2>
                      </Link>
                    </Link2>
                  </Link>
                </li>
                {/* <li style={{ marginRight: "28rem" }}>
                </li> */}
                <li
                  style={{
                    width: `${
                      localStorage.getItem("lang") == "ko" ? "220px" : "270px"
                    }`,
                    left: 0,
                    right: 0,
                    backgroundColor: "#f5f5f5",
                    padding: "12px",
                    borderRadius: "30px",
                    top: "15px",
                    textAlign: "center",
                    marginRight: "30px",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  <a
                    className="business"
                    href="#/company/1"
                    style={{ color: "#999999" }}
                  >
                    {localStorage.getItem("lang") == "eng"
                      ? "Solution"
                      : "솔루션"}
                  </a>
                  <a style={{ margin: "7px", color: "#dddddd" }}>|</a>
                  <a
                    className="business"
                    href="#/company/2"
                    style={{ color: "#999999" }}
                  >
                    {localStorage.getItem("lang") == "eng"
                      ? "Contents"
                      : "콘텐츠"}
                  </a>
                  <a style={{ margin: "7px", color: "#dddddd" }}>|</a>
                  <a
                    className="business"
                    href="#/company/3"
                    style={{ color: "#999999" }}
                  >
                    {localStorage.getItem("lang") == "eng"
                      ? "Platform"
                      : "플랫폼"}
                  </a>
                </li>

                <li style={{ marginRight: "100px", padding: "10px" }}>
                  <Link
                    activeClass="active"
                    to="page"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <Link2 className="nav-item nl-simple nav-link" to="/career">
                      <Link
                        activeClass="active"
                        to="page"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        <Link2 class="currentRoute" to="/career">
                          {this.props.Text4}
                        </Link2>
                      </Link>
                    </Link2>
                  </Link>
                </li>
                <li style={{ marginRight: "100px", padding: "10px" }}>
                  <Link
                    activeClass="active"
                    to="page"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <Link2
                      className="nav-item nl-simple nav-link"
                      to="/contact"
                    >
                      <Link
                        activeClass="active"
                        to="page"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        <Link2 class="currentRoute" to="/contact">
                          {this.props.Text5}
                        </Link2>
                      </Link>
                    </Link2>
                  </Link>
                </li>
                <li style={{ padding: "10px" }}>
                  <Link
                    activeClass="active"
                    to="page"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <Link2 className="nav-item nl-simple nav-link" to="/notice">
                      <Link
                        activeClass="active"
                        to="page"
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        <Link2 class="currentRoute" to="/notice">
                          {this.props.Text6}
                        </Link2>
                      </Link>
                    </Link2>
                  </Link>
                </li>
              </ul>
            </div>{" "}
            {/* End Navigation Menu */}
            <Link2 to="/" className="logo-white">
              <img
                style={{ marginTop: "21px", marginBottom: "25px", opacity: 0 }}
                src={require("./images/logo_.png")}
                width={150}
                height="auto"
                alt="header-logo"
              />
            </Link2>
          </div>{" "}
          {/* End container */}
        </nav>{" "}
        {/* End navbar  */}
      </header>
    );
  }
}

MainNav.propTypes = propTypes;
MainNav.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  console.log(state.lang.text);
  var text1;
  var text2;
  var text3;
  var text4;
  var text5;
  var text6;

  switch (state.lang.text) {
    case "KOR":
      text1 = "제품소개.";
      text2 = "회사소개.";
      text3 = "관련기사.";
      text4 = "협력사.";
      text5 = "연락.";

      break;

    case "ENG":
      text1 = "홈";
      text2 = `${
        localStorage.getItem("lang") == "eng" ? "Company" : "회사소개"
      }`;
      text3 = `${
        localStorage.getItem("lang") == "eng" ? "Business" : "사업분야"
      }`;
      text4 = `${
        localStorage.getItem("lang") == "eng" ? "Career" : "채용정보"
      }`;
      text5 = "Contact Us";
      text6 = `${localStorage.getItem("lang") == "eng" ? "Notice" : "공고"}`;

      break;
  }

  return {
    Text1: text1,
    Text2: text2,
    Text3: text3,
    Text4: text4,
    Text5: text5,
    Text6: text6,
  };
};

const mapDispatchProps = (dispatch) => {
  // return bindActionCreators(actions, dispatch);
  // return {
  //   handleSetKOR: text => {
  //     dispatch(actions.setKOR(text));
  //   },
  //   handleSetENG: text => {
  //     dispatch(actions.setENG(text));
  //   }
  // };
};

export default MainNav;

import React, { Component } from "react";
import Home from "./components/Home";
import Service from "./components/Services";
import Company from "./components/Company";
import Press from "./components/Press";
import Relations from "./components/Relations";
import Notice from "./components/Notice";
import "./App.css";
import { Route, HashRouter } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <HashRouter>
        <div>
          <Route exact path="/" component={Home} />
          <Route path="/intro" component={Service} />
          <Route path="/company" component={Company} />
          <Route path="/career" component={Press} />
          <Route path="/contact" component={Relations} />
          <Route path="/notice" component={Notice} />
        </div>
      </HashRouter>
    );
  }
}

export default App;

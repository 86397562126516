import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

// import { createStore } from "redux";
// import reducers from "./reducers";

// import { Provider } from "react-redux";

// import * as serviceWorker from "./serviceWorker";
// import { ParallaxProvider } from 'react-scroll-parallax';

// const store = createStore(reducers);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


// serviceWorker.unregister();
